import { Navigate, Route } from 'react-router-dom';
import Main from './components/layout/main-layout/main';
import {
  APPLICATION_STATUS,
  AVAILABLE_OPENINGS,
  JOIN_OUR_NETWORK,
  STEPS,
} from './constants/routes.constants';
import ApplicationStatusPage from './pages/application-status/application-status.page';
import ApplicationStepsRoutes from './pages/application-steps/application-steps.routes';
import AvailableOpeningsPage from './pages/available-openings/available-openings.page';
import JoinOurNetworkPage from './pages/join-our-network/join-our-network.page';
import { RedirectTo404 } from './services/sentry/error-boundary';
import SentryRoutes from './services/sentry/sentry-routes';

function MainRoutes() {
  return (
    <SentryRoutes>
      <Route path="/" element={<Main />}>
        <Route path="/" element={<Navigate to={AVAILABLE_OPENINGS} />} />
        <Route path={`/${JOIN_OUR_NETWORK}`}>
          <Route path=":openingId" element={<JoinOurNetworkPage />} />
        </Route>
        <Route
          path={`/${AVAILABLE_OPENINGS}/*`}
          element={<AvailableOpeningsPage />}
        />
        <Route path={`/${STEPS}/*`} element={<ApplicationStepsRoutes />} />
        <Route
          path={`/${APPLICATION_STATUS}/:statusKey`}
          element={<ApplicationStatusPage />}
        />
        <Route path={'/*'} element={<RedirectTo404 />} />
      </Route>
    </SentryRoutes>
  );
}

export default MainRoutes;
