import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Separator,
} from '@dispatch-ui/react';
import { useContext } from 'react';
import { LuUserCircle } from 'react-icons/lu';
import Logo from '../../../../../assets/images/logo-dispatch.svg?react';
import { CurrentUserContext } from '../../../providers/current-user.context';
import LanguageContext from '../../../providers/language.context';
import Typography from '../../typography';

function Header({
  multiLanguageAvailable = true,
}: {
  multiLanguageAvailable?: boolean;
}) {
  const { changeLanguage, currentLanguage } = useContext(LanguageContext);
  const { currentUser } = useContext(CurrentUserContext);

  return (
    <div>
      {multiLanguageAvailable && (
        <div className="absolute w-full">
          <div className="mr-8 grid h-[81px] items-center justify-items-end text-white">
            <div className="flex items-center justify-center text-white">
              <Button
                data-testid="language-en-button"
                className="extrabold text-base text-white p-1"
                variant="link"
                onClick={() => {
                  changeLanguage('en-US');
                }}
              >
                <Typography
                  size="sm"
                  color="white"
                  className={
                    currentLanguage === 'en-US' ? 'underline' : 'no-underline'
                  }
                >
                  En
                </Typography>
              </Button>
              /
              <Button
                data-testid="language-es-button"
                className="text-base text-white p-1"
                variant="link"
                onClick={() => {
                  changeLanguage('es');
                }}
              >
                <Typography
                  size="sm"
                  color="white"
                  className={
                    currentLanguage !== 'en-US' ? 'underline' : 'no-underline'
                  }
                >
                  Es
                </Typography>
              </Button>
              {!currentUser?.email && (
                <Button
                  data-testid="login-button"
                  variant="outline"
                  className="ml-4 text-base text-white bg-primary"
                  onClick={() => {
                    window.location.href = '/auth';
                  }}
                >
                  Login
                </Button>
              )}
              {currentUser?.email && (
                <div className="ml-2">
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <Button
                        variant="secondary"
                        size="icon"
                        className="rounded-full ml-2"
                        data-testid="user-menu"
                      >
                        <LuUserCircle className="h-5 w-5" />
                        <span className="sr-only">Toggle user menu</span>
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="end">
                      <DropdownMenuItem>
                        <div className="text-center">
                          <div className="text-lg">
                            {`${currentUser.first_name} ${currentUser.last_name}`}
                          </div>
                          <div>{currentUser.email}</div>
                        </div>
                      </DropdownMenuItem>
                      <Separator />
                      <DropdownMenuItem
                        onClick={() => {
                          window.location.href = '/auth/logout';
                        }}
                      >
                        Logout
                      </DropdownMenuItem>
                    </DropdownMenuContent>
                  </DropdownMenu>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <div className="bg-primary flex h-[81px] items-center justify-center">
        <div>
          <Button
            data-testid="logo-button"
            variant="link"
            className="cursor-pointer z-50"
            onClick={() => {
              window.location.href = '/';
            }}
          >
            <Logo className="z-0" />
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Header;
