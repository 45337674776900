import { useContext, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MainContentCard from '../../components/layout/main-layout/main-content-card';
import Typography from '../../components/typography/typography';
import {
  STATUS_KEY_ALREADY_APPLIED,
  STATUS_KEY_ALREADY_USER,
  STATUS_KEY_APPLICATION_SENT,
  STATUS_KEY_REJECTED_APPLICANT_DOES_NOT_MEET_REQUIREMENTS,
  STATUS_KEY_REJECTED_APPLICANT_INVALID_DOCUMENTATION,
  STATUS_KEY_REJECTED_APPLICANTS_VEHICLE_TYPE_NOT_NEEDED,
  STATUS_KEY_REJECTED_BACKGROUND_CHECK_FAIL,
  STATUS_KEY_REJECTED_INACTIVE_APPLICATION,
  STATUS_KEY_REJECTED_OPENING_DEACTIVATED,
  STATUS_KEY_REJECTED_OTHER,
  STATUS_KEY_REJECTED_PERSONAL_INFO_NOT_MEET_REQUIREMENTS,
  STATUS_KEY_REJECTED_VEHICLE_TYPE_MODIFIED,
} from '../../constants/routes.constants';
import { useTranslation } from '../../hooks/use-translation';
import { CurrentUserContext } from '../../providers/current-user.context';

function ApplicationStatusPage(): JSX.Element {
  const { statusKey } = useParams();
  const navigate = useNavigate();

  const { fetchCurrentUser, currentUser } = useContext(CurrentUserContext);

  const { t } = useTranslation('application-status');

  useEffect(() => {
    if (!currentUser) fetchCurrentUser();
  }, [currentUser, fetchCurrentUser]);

  const {
    pageTitle,
    pageContent,
  }: {
    pageTitle: string;
    pageContent: React.ReactElement;
  } = useMemo(() => {
    switch (statusKey) {
      case STATUS_KEY_ALREADY_APPLIED:
        return {
          pageTitle: t('ALREADY_APPLIED_TITLE'),
          pageContent: (
            <Typography size="sm">{t('ALREADY_APPLIED_DESC')}</Typography>
          ),
        };
      case STATUS_KEY_ALREADY_USER:
        return {
          pageTitle: t('ALREADY_USER_TITLE'),
          pageContent: (
            <Typography size="sm">{t('ALREADY_USER_DESC')}</Typography>
          ),
        };
      case STATUS_KEY_APPLICATION_SENT:
        return {
          pageTitle: t('APPLICATION_SENT_TITLE'),
          pageContent: (
            <Typography size="sm">{t('APPLICATION_SENT_DESC')}</Typography>
          ),
        };
      case STATUS_KEY_REJECTED_APPLICANT_DOES_NOT_MEET_REQUIREMENTS:
        return {
          pageTitle: t('REJECTED_APPLICANT_DOES_NOT_MEET_REQUIREMENTS_TITLE'),
          pageContent: (
            <Typography size="sm">
              {t('REJECTED_APPLICANT_DOES_NOT_MEET_REQUIREMENTS_DESC')}
            </Typography>
          ),
        };
      case STATUS_KEY_REJECTED_APPLICANT_INVALID_DOCUMENTATION:
        return {
          pageTitle: t('REJECTED_APPLICANT_INVALID_DOCUMENTATION_TITLE'),
          pageContent: (
            <Typography size="sm">
              {t('REJECTED_APPLICANT_INVALID_DOCUMENTATION_DESC')}
            </Typography>
          ),
        };
      case STATUS_KEY_REJECTED_APPLICANTS_VEHICLE_TYPE_NOT_NEEDED:
        return {
          pageTitle: t('REJECTED_APPLICANTS_VEHICLE_TYPE_NOT_NEEDED_TITLE'),
          pageContent: (
            <Typography size="sm">
              {t('REJECTED_APPLICANTS_VEHICLE_TYPE_NOT_NEEDED_DESC')}
            </Typography>
          ),
        };
      case STATUS_KEY_REJECTED_BACKGROUND_CHECK_FAIL:
        return {
          pageTitle: t('REJECTED_BACKGROUND_CHECK_FAIL_TITLE'),
          pageContent: (
            <Typography size="sm">
              {t('REJECTED_BACKGROUND_CHECK_FAIL_DESC')}
            </Typography>
          ),
        };
      case STATUS_KEY_REJECTED_INACTIVE_APPLICATION:
        return {
          pageTitle: t('REJECTED_INACTIVE_APPLICATION_TITLE'),
          pageContent: (
            <Typography size="sm">
              {t('REJECTED_INACTIVE_APPLICATION_DESC')}
            </Typography>
          ),
        };
      case STATUS_KEY_REJECTED_OPENING_DEACTIVATED:
        return {
          pageTitle: t('REJECTED_OPENING_DEACTIVATED_TITLE'),
          pageContent: (
            <Typography size="sm">
              {t('REJECTED_OPENING_DEACTIVATED_DESC')}
            </Typography>
          ),
        };
      case STATUS_KEY_REJECTED_OTHER:
        return {
          pageTitle: t('REJECTED_OTHER_TITLE'),
          pageContent: (
            <Typography size="sm">{t('REJECTED_OTHER_DESC')}</Typography>
          ),
        };
      case STATUS_KEY_REJECTED_PERSONAL_INFO_NOT_MEET_REQUIREMENTS:
        return {
          pageTitle: t('REJECTED_PERSONAL_INFO_NOT_MEET_REQUIREMENTS_TITLE'),
          pageContent: (
            <Typography size="sm">
              {t('REJECTED_PERSONAL_INFO_NOT_MEET_REQUIREMENTS_DESC')}
              <ul className="list-disc p-4">
                <li>
                  {t(
                    'REJECTED_PERSONAL_INFO_NOT_MEET_REQUIREMENTS_DESC_ITEM_3',
                  )}
                </li>
                <li>
                  {t(
                    'REJECTED_PERSONAL_INFO_NOT_MEET_REQUIREMENTS_DESC_ITEM_2',
                  )}
                </li>
                <li>
                  {t(
                    'REJECTED_PERSONAL_INFO_NOT_MEET_REQUIREMENTS_DESC_ITEM_1',
                  )}
                </li>
              </ul>
            </Typography>
          ),
        };
      case STATUS_KEY_REJECTED_VEHICLE_TYPE_MODIFIED:
        return {
          pageTitle: t('REJECTED_VEHICLE_TYPE_MODIFIED_TITLE'),
          pageContent: (
            <Typography size="sm">
              {t('REJECTED_VEHICLE_TYPE_MODIFIED_DESC')}
            </Typography>
          ),
        };
      default:
        return {
          pageTitle: '',
          pageContent: <div />,
        };
    }
  }, [statusKey, t]);

  useEffect(() => {
    if (
      !statusKey ||
      ![
        STATUS_KEY_ALREADY_APPLIED,
        STATUS_KEY_ALREADY_USER,
        STATUS_KEY_APPLICATION_SENT,
        STATUS_KEY_REJECTED_APPLICANT_DOES_NOT_MEET_REQUIREMENTS,
        STATUS_KEY_REJECTED_APPLICANT_INVALID_DOCUMENTATION,
        STATUS_KEY_REJECTED_APPLICANTS_VEHICLE_TYPE_NOT_NEEDED,
        STATUS_KEY_REJECTED_BACKGROUND_CHECK_FAIL,
        STATUS_KEY_REJECTED_INACTIVE_APPLICATION,
        STATUS_KEY_REJECTED_OPENING_DEACTIVATED,
        STATUS_KEY_REJECTED_OTHER,
        STATUS_KEY_REJECTED_PERSONAL_INFO_NOT_MEET_REQUIREMENTS,
        STATUS_KEY_REJECTED_VEHICLE_TYPE_MODIFIED,
      ].includes(statusKey)
    ) {
      navigate('/');
    }
  }, [statusKey, navigate]);

  return (
    <MainContentCard
      title={pageTitle}
      content={<div className="mt-2">{pageContent}</div>}
    />
  );
}

export default ApplicationStatusPage;
