import { Outlet } from 'react-router-dom';
import Header from './header';

function Main() {
  return (
    <div>
      <header>
        <Header />
      </header>
      <main className="my-12 flex items-center justify-center">
        <Outlet />
      </main>
    </div>
  );
}

export default Main;
