import { DirectUpload } from 'activestorage';
import { DocumentType } from '../types/document.types';

export type DocumentToUpload = {
  file: File;
  documentType: DocumentType;
};

export type UploadMultipleImagesParams = {
  applicantId: string;
  documents: DocumentToUpload[];
};

export type UploadSingleImageParams = {
  applicantId: string;
  document: DocumentToUpload;
};

export type HandleImageUploadResponse = {
  signedId: string;
  documentType: DocumentType;
};

const uploadToS3 = async (
  directUploadUrl: string,
  file: File,
): Promise<string> => {
  const upload = new DirectUpload(file, directUploadUrl);

  return new Promise((resolve, reject) => {
    upload.create((error: Error, blob: ActiveStorage.Blob) => {
      if (error) {
        reject(error);
      } else {
        resolve(blob.signed_id);
      }
    });
  });
};

const handleImageUpload = async ({
  document,
}: UploadSingleImageParams): Promise<string> => {
  // TODO We need to defined if we call to an endpoint to get the directUploadsUrl (presigned URL)
  // or add the url as an env var

  /*   const options = {
    method: 'GET',
    params: {
      file_name: document.file.name,
      file_type: document.file.type,
      document_type: document.documentType,
      applicant_id: applicantId,
    },
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
    },
  }; */

  // const presignedUrl = `${API_BASE_URL}${API_RELATIVE_PATH}${S3_PRESIGNED_URL}`;
  // const response = await fetch(presignedUrl, options);
  // const data = await response.json();

  const directUploadsUrl = '/rails/active_storage/direct_uploads'; // await data.direct_upload.url;

  return uploadToS3(directUploadsUrl, document.file);
};

const useFileUploadS3 = () => {
  const handleSingleImageUpload = async ({
    applicantId,
    document,
  }: UploadSingleImageParams): Promise<HandleImageUploadResponse> => {
    const signedId = await handleImageUpload({ applicantId, document });

    return {
      documentType: document.documentType,
      signedId,
    };
  };

  const handleMultipleImageUpload = async ({
    applicantId,
    documents,
  }: UploadMultipleImagesParams): Promise<HandleImageUploadResponse[]> => {
    return Promise.all(
      documents.map(async (document: DocumentToUpload) => {
        const signedId = await handleImageUpload({ applicantId, document });

        return {
          documentType: document.documentType,
          signedId,
        };
      }),
    );
  };

  return { handleSingleImageUpload, handleMultipleImageUpload };
};

export default useFileUploadS3;
