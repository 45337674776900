import { useEffect, useState } from 'react';
import { NavigateFunction } from 'react-router-dom';
import {
  APPLICATION_STATUS,
  ApplicationStepRoutes,
  STATUS_KEY_APPLICATION_SENT,
} from '../constants/routes.constants';

export type UseStepFormProps = {
  applicantId: string;
  currentStep: ApplicationStepRoutes;
  steps: ApplicationStepRoutes[];
  navigate: NavigateFunction;
};

export type UseStepForm = {
  activeStep?: ApplicationStepRoutes;
  isNextEnable: boolean;
  isPrevEnable: boolean;
  onNextStep: () => void;
  onPrevStep: (routeParams?: string) => void;
  getPreviousStep: () => ApplicationStepRoutes;
  isFirstStep: boolean;
  isLastStep: boolean;
};

export const useStepForm = ({
  applicantId,
  currentStep,
  steps,
  navigate,
}: UseStepFormProps): UseStepForm => {
  const [indexActiveStep, setIndexActiveStep] = useState<number>(
    steps.indexOf(currentStep),
  );
  const [isNextEnable, setIsNextEnable] = useState(
    steps.indexOf(currentStep) < steps.length - 1,
  );
  const [isPrevEnable, setIsPrevEnable] = useState(
    steps.indexOf(currentStep) !== 0,
  );

  const isFirstStep = currentStep === steps[0];
  const isLastStep = currentStep === steps[steps.length - 1];

  useEffect(() => {
    const newIndexActiveStep = steps.indexOf(currentStep);
    setIndexActiveStep(newIndexActiveStep);

    if (newIndexActiveStep < steps.length - 1) setIsNextEnable(true);
    if (newIndexActiveStep > 0) setIsPrevEnable(true);
    else setIsPrevEnable(false);
  }, [currentStep, steps]);

  const onNextStep = () => {
    if (!isNextEnable) return;

    if (isLastStep) {
      navigate(`/${APPLICATION_STATUS}/${STATUS_KEY_APPLICATION_SENT}`);
      return;
    }

    const newIndexActiveStep = indexActiveStep + 1;
    setIndexActiveStep(newIndexActiveStep);

    navigate(`${steps[newIndexActiveStep]}/applicant/${applicantId}`);
    window.scrollTo(0, 0);
  };

  const onPrevStep = () => {
    if (!isPrevEnable) return;

    const newIndexActiveStep = indexActiveStep - 1;
    setIndexActiveStep(newIndexActiveStep);

    navigate(`${steps[newIndexActiveStep]}/applicant/${applicantId}`);
    window.scrollTo(0, 0);
  };

  const getPreviousStep = () => {
    return steps[indexActiveStep - 1];
  };

  return {
    activeStep: steps[indexActiveStep],
    onNextStep,
    onPrevStep,
    isNextEnable,
    isPrevEnable,
    getPreviousStep,
    isFirstStep,
    isLastStep,
  };
};
