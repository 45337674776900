import Intercom from '@intercom/messenger-js-sdk';
import { useContext, useEffect, useState } from 'react';
import {
  API_BASE_URL,
  API_RELATIVE_PATH,
} from '../../constants/util.constants';
import { CurrentUserContext } from '../../providers/current-user.context';

function fetchIntercomEnvVar() {
  const url = `${API_BASE_URL}${API_RELATIVE_PATH}/intercom-info`;

  return fetch(url).then(res => res.json());
}

function IntercomContactWrapper({ children }: { children: React.ReactNode }) {
  const { currentUser } = useContext(CurrentUserContext);

  const [intercomAppId, setIntercomAppId] = useState<string | null>(null);

  useEffect(() => {
    if (!intercomAppId) {
      fetchIntercomEnvVar().then(envVar => {
        setIntercomAppId(envVar.intercom_app_id);
      });
    }
  }, [intercomAppId]);

  if (currentUser && currentUser.email && intercomAppId) {
    Intercom({
      app_id: intercomAppId,
      email: currentUser.email,
    });
  }

  return <div>{children}</div>;
}

export default IntercomContactWrapper;
