import { PostHogClientProvider } from '@dispatch/posthog-openfeature-provider-js';
import { OpenFeature, OpenFeatureProvider } from '@openfeature/react-sdk';
import { ReactNode } from 'react';

const posthogConfig = {
  token: 'phc_Xd3R8pMnOPZJULEbdcYlXZcE8hdMECN9HOragYms0tn',
};

const provider = new PostHogClientProvider(posthogConfig);

OpenFeature.setProvider(provider);

function FeatureFlagProvider({ children }: { children: ReactNode }): ReactNode {
  return <OpenFeatureProvider>{children}</OpenFeatureProvider>;
}

export default FeatureFlagProvider;
