export enum DocumentType {
  VEHICLE_PHOTOS = 'vehicle-photos',
  DRIVER_SKILLS_AND_CERTIFICATIONS = 'driver-skills-and-certifications',
  PROOF_OF_INSURANCE = 'proof-of-insurance',
  PROFILE_PHOTO = 'profile-photo',
  DRIVER_LICENSE_FRONT = 'driver-license-front',
  DRIVER_LICENSE_BACK = 'driver-license-back',
}

export type Document = {
  name: string;
  url: string;
};

export type ExistingFile = {
  id?: string;
  signed_id: string;
  url?: string;
  filename?: string;
  _destroy?: boolean;
};

export enum SigningDocumentEnum {
  CONTRACTOR_AGREEMENTS = 'contractor-agreements',
  PAIC = 'paic',
}

export enum DocumentToSignStatus {
  COMPLETED = 'completed',
  SENT = 'sent',
  PROCESSING = 'processing',
}

export type DocumentToSign = {
  status?: DocumentToSignStatus;
  name?: string;
  document_details_url?: string;
  docusign_template_id?: string;
  envelope_id?: string;
};

export type DocusignEmbedUrlResponse = {
  url: string;
  envelope_id: string;
};
