import { Alert, AlertDescription, Button } from '@dispatch-ui/react';
import { useContext, useEffect } from 'react';
import { LuAlertCircle } from 'react-icons/lu';
import { ApplicantStatusEnum } from '../../__generated__/graphql';
import {
  APPLICATION_STEP_PERSONAL_INFO,
  STEPS,
} from '../../constants/routes.constants';
import { useTranslation } from '../../hooks/use-translation';
import { CurrentUserContext } from '../../providers/current-user.context';
import { OnboardingApplication } from '../../types/applicant.types';
import {
  validateAlreadyApplied,
  validateAlreadyDriver,
  validateBackgroundCheckFailed,
} from './util';

function ApplicationItems({
  applications,
  buttonText,
}: {
  applications: OnboardingApplication[];
  buttonText: string;
}): JSX.Element {
  return (
    <ul className="list-disc ml-6">
      {applications.map(application => (
        <li key={application.applicant.id} className="">
          {`${application.opening?.location} - ${application.opening?.position}`}
          <Button
            variant="link"
            data-testid="non-rejected-resume-button"
            className="p-0 h-[30px] font-bold ml-2"
            onClick={() => {
              window.location.href = `/${STEPS}/${APPLICATION_STEP_PERSONAL_INFO}/applicant/${application.applicant.id}`;
            }}
          >
            {buttonText}
          </Button>
        </li>
      ))}
    </ul>
  );
}

type MultipleApplicationsAlertProps = {
  ongoingApplication?: OnboardingApplication | undefined;
};

function MultipleApplicationsAlert({
  ongoingApplication,
}: MultipleApplicationsAlertProps): JSX.Element {
  const { t } = useTranslation('multiple-applications');
  const { currentUser, fetchCurrentUser, existingApplications } =
    useContext(CurrentUserContext);

  const backgroundCheckFailed =
    validateBackgroundCheckFailed(existingApplications);

  const alreadyDriver = validateAlreadyDriver(existingApplications);

  const alreadyAppied = validateAlreadyApplied(existingApplications);

  useEffect(() => {
    if (!currentUser) {
      fetchCurrentUser();
    }
  }, [currentUser, fetchCurrentUser]);

  if (
    !existingApplications ||
    existingApplications.length === 0 ||
    (existingApplications.length === 1 && ongoingApplication)
  )
    return <div />;

  const applicationsByUser = ongoingApplication
    ? existingApplications.filter(
        application =>
          application.applicant.id !== ongoingApplication?.applicant.id,
      )
    : existingApplications;

  if (alreadyDriver)
    return (
      <Alert variant="destructive">
        <AlertDescription>{t('ALREADY_DRIVER')}</AlertDescription>
      </Alert>
    );

  if (alreadyAppied)
    return (
      <Alert variant="destructive">
        <AlertDescription>{t('ALREADY_APPLIED')}</AlertDescription>
      </Alert>
    );

  if (backgroundCheckFailed)
    return (
      <Alert variant="destructive">
        <AlertDescription>{t('BACKGROUND_CHECK_FAIL')}</AlertDescription>
      </Alert>
    );

  const rejectedApplications = applicationsByUser.filter(
    application =>
      application.applicant.status === ApplicantStatusEnum.ApplicantRejected,
  );

  const nonRejectedApplications = applicationsByUser.filter(
    application =>
      application.applicant.status !== ApplicantStatusEnum.ApplicantRejected,
  );

  const onlyRejectedApplications =
    rejectedApplications.length > 0 && nonRejectedApplications.length === 0;

  return (
    <Alert className={onlyRejectedApplications ? 'default' : 'bg-yellow-200'}>
      <AlertDescription>
        {nonRejectedApplications && nonRejectedApplications.length > 0 && (
          <>
            {t('THERE_ARE_APPLICATIONS_FOR_OTHER_POSITIONS')}:
            <ApplicationItems
              applications={nonRejectedApplications}
              buttonText="Resume"
            />
          </>
        )}
        {rejectedApplications && rejectedApplications.length > 0 && (
          <>
            {t('OTHER_POSITIONS_REJECTED')}:
            <ApplicationItems
              applications={rejectedApplications}
              buttonText="See details"
            />
          </>
        )}
        <div className="flex items-center">
          <LuAlertCircle />
          <div className="mx-2">{t('CAN_ONLY_APPLY_FOR_ONE_POSITION')}</div>
        </div>
      </AlertDescription>
    </Alert>
  );
}

export default MultipleApplicationsAlert;
