import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogPortal,
} from '@dispatch-ui/react';
import { useMutation } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import ButtonSpinner from '../../../components/button-spinner';
import LoadingIFrame from '../../../components/iframe-loading/iframe-loading';
import Typography from '../../../components/typography';
import { useTranslation } from '../../../hooks/use-translation';
import {
  DocumentToSign,
  DocumentToSignStatus,
  DocusignEmbedUrlResponse,
} from '../../../types/document.types';
import { OutletContextProps } from '../application-steps.page';
import {
  getDocusignDocumentUrl,
  procesingDocument,
} from './review-and-sign.service';
import { loadDocuSign } from './util';

type DocumentToSignParams = {
  document: DocumentToSign;
  documentTitle: string;
  documentDescription: string;
  hasErrors?: boolean;
};

function SigningDocument({
  document,
  documentTitle,
  documentDescription,
  hasErrors = false,
}: DocumentToSignParams): JSX.Element {
  const { t } = useTranslation('review-and-sign');

  const { onboardingApplication, setOnboardingApplication } =
    useOutletContext<OutletContextProps>();
  const applicantId = onboardingApplication?.applicant.id || '';
  const [iFrameOpened, setIFrameOpened] = useState<boolean>(false);

  const [status, setStatus] = useState<DocumentToSignStatus | undefined>(
    document?.status,
  );
  const [signingUrl, setSigningUrl] = useState<string>('');
  const [envelopeId, setEnvelopeId] = useState<string>('');

  const documentAlreadySigned =
    status &&
    [DocumentToSignStatus.COMPLETED, DocumentToSignStatus.PROCESSING].includes(
      status,
    );

  const { mutate: createDocusignEnvelopMutationFunction, isLoading } =
    useMutation({
      mutationFn: () => {
        return getDocusignDocumentUrl({
          documentTemplateId: document.docusign_template_id || '',
          applicantId,
        });
      },
      onSuccess: (response: DocusignEmbedUrlResponse) => {
        if (onboardingApplication && onboardingApplication.applicant) {
          setSigningUrl(response.url);
          setEnvelopeId(response.envelope_id);
          setIFrameOpened(true);
        }
      },
    });

  const onClickReviweAndSign = () => {
    createDocusignEnvelopMutationFunction();
  };

  useEffect(() => {
    if (iFrameOpened && onboardingApplication && signingUrl) {
      loadDocuSign({
        signingUrl,
        onDocumentSigned: (event: unknown) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          if ((event as any).sessionEndType !== 'signing_complete') {
            enqueueSnackbar('Docusign seesion end', { variant: 'error' });
          } else {
            procesingDocument({ applicantId, envelopeId })
              .then(res => {
                const documentRes = (res?.applicant.documents || []).find(
                  doc =>
                    doc.docusign_template_id === document.docusign_template_id,
                );

                setStatus(documentRes?.status);
                setOnboardingApplication(res);
              })
              .finally(() => {
                setIFrameOpened(false);
              });
          }
          setIFrameOpened(false);
        },
        onError: (ex, message) => {
          // eslint-disable-next-line no-console
          console.error(message, ex);
          enqueueSnackbar(message, { variant: 'error' });
        },
      });
    }
  }, [
    signingUrl,
    onboardingApplication,
    document,
    setOnboardingApplication,
    applicantId,
    envelopeId,
    iFrameOpened,
  ]);

  return (
    <div
      className={`${hasErrors ? 'border-red' : 'border-border'} rounded-sm border-2 p-3 pt-1 my-2`}
    >
      <div className="flex items-start">
        <div className="grow mt-2">
          <Typography size="base" fontWeight="semibold" color="black">
            <div className="flex">
              <div className="text-red">*&nbsp;</div>
              {documentTitle}
            </div>
          </Typography>
          <Typography size="sm" color="gray-500" className="mt-2">
            {documentDescription}
          </Typography>
        </div>
        <div>
          {!documentAlreadySigned && (
            <ButtonSpinner
              type="button"
              data-testid="review-and-sign-button"
              variant="link"
              className="font-bold text-lg"
              onClick={onClickReviweAndSign}
              loading={isLoading}
            >
              {t('REVIEW_AND_SIGN_ACTION')}
            </ButtonSpinner>
          )}
          {documentAlreadySigned && (
            <Typography
              className="pr-4 pt-2"
              size="lg"
              fontWeight="bold"
              color="gray-400"
            >
              {t('SIGNED', { ns: 'common' })}
            </Typography>
          )}

          <AlertDialog open={iFrameOpened && !!signingUrl}>
            <AlertDialogPortal>
              <AlertDialogContent className="absolute max-w-full min-h-[95svh] top-[50svh]">
                <div>
                  {signingUrl && (
                    <div
                      className="w-full h-[80vh]"
                      data-testid="docusign-agreement"
                    >
                      <LoadingIFrame
                        message={t('IFRAME_TAKES_FEW_SECONDS')}
                        duration={10000}
                      />
                      <div
                        className="docusign-agreement h-[80vh]"
                        id="agreement"
                      />
                    </div>
                  )}
                  <div className="mt-4">
                    <AlertDialogCancel
                      data-testid="alert-cancel-button"
                      onClick={() => {
                        setIFrameOpened(false);
                      }}
                    >
                      {t('CANCEL', { ns: 'common' })}
                    </AlertDialogCancel>
                  </div>
                </div>
              </AlertDialogContent>
            </AlertDialogPortal>
          </AlertDialog>
        </div>
      </div>
    </div>
  );
}

export default SigningDocument;
