import {
  ApplicantStatusEnum,
  RejectionReasonTypeEnum,
} from '../../__generated__/graphql';
import { OnboardingApplication } from '../../types/applicant.types';

export const validateBackgroundCheckFailed = (
  existingApplications: OnboardingApplication[],
) =>
  existingApplications.filter(
    application =>
      application.applicant.rejection_reason_type ===
      RejectionReasonTypeEnum.BackgroundCheckFail,
  ).length > 0;

export const validateAlreadyDriver = (
  existingApplications: OnboardingApplication[],
) =>
  existingApplications.filter(
    application =>
      application.applicant.status === ApplicantStatusEnum.ApplicantApproved,
  ).length > 0;

export const validateAlreadyApplied = (
  existingApplications: OnboardingApplication[],
) =>
  existingApplications.filter(
    application =>
      application.applicant.status === ApplicantStatusEnum.ApplicantApplied,
  ).length > 0;
