import { Analytics } from '@dispatch/analytics';
import posthogPlugin from '@dispatch/analytics-plugin-posthog-js';

const analytics = Analytics({
  app: 'driver-onboarding',
  plugins: [
    posthogPlugin({
      token: 'phc_Xd3R8pMnOPZJULEbdcYlXZcE8hdMECN9HOragYms0tn',
      enabled: true,
      options: {},
    }),
  ],
});

export default { analytics };
