import {
  API_BASE_URL,
  API_RELATIVE_PATH,
} from '../../constants/util.constants';
import { OnboardingApplication } from '../../types/applicant.types';

const getOnboardingApplication = async (
  applicantId: string,
): Promise<OnboardingApplication> => {
  const url = `${API_BASE_URL}${API_RELATIVE_PATH}/applicants/${applicantId}`;

  return fetch(url).then(res => res.json());
};

export default getOnboardingApplication;
